export const sidebtns = [
  {
    to: "/",
    name: "Trending"
  },
  {
    to: "/tech",
    name: "Technology"
  },
  {
    to: "/freebies",
    name: "Freebies"
  },
  {
    to: "/tips",
    name: "Tips & Tricks"
  },
  {
    to: "/business",
    name: "Business"
  },
  {
    to: "/productivity",
    name: "Productivity"
  }
];
